import { Card, Icon } from '@dayinsure/components';
import { formatInUKLocalTime, useNavigateWithReferrer } from '@dayinsure/shared';
import clsx from 'clsx';
import { PoliciesPaths } from '../../../../routes';

type PolicyPendingChangesMessageProps = {
  changesStartDateUtc: string;
  pendingChanges: boolean;
  testId?: string;
};

export const PolicyPendingChangesMessage = ({
  changesStartDateUtc,
  pendingChanges,
  testId,
}: PolicyPendingChangesMessageProps) => {
  const navigate = useNavigateWithReferrer();
  const handleClick = () => {
    navigate(PoliciesPaths.UpcomingChanges);
  };

  const changesDay = formatInUKLocalTime('dd/MM/yyyy', changesStartDateUtc);
  const changesTime = formatInUKLocalTime('HH:mm', changesStartDateUtc);

  if (!pendingChanges) {
    return null;
  }

  return (
    <section>
      <Card
        classNames={clsx('flex items-center', 'mb-8', 'mt-8 lg:mt-12')}
        paddingLevel="small"
        testId={`${testId}_card`}
      >
        <Icon className="text-main-content-1" size="2rem" name="warning" />
        <div className="ml-6 text-main-content-1">
          This policy has changes that are due to start on {changesDay} at {changesTime}.{' '}
          <button
            type="button"
            data-testid={`${testId}-link-changes`}
            onClick={handleClick}
            className="font-semibold underline text-link"
          >
            View upcoming changes.
          </button>
        </div>
      </Card>
    </section>
  );
};
